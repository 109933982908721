<template>
 <div id="dualoo-iframe-1"></div>

</template>

<script>
export default {
  name: 'DualooIFrame',
  props: {
    portalUrl: {
      type: String,
      required: true
    }
  },
  created () {
    const url = this.portalUrl
    const script = document.createElement('script')
    script.src = 'https://jobs.dualoo.com/content/iframe/iframe_loader_v3.js'
    script.onload = function () {
      window.dualooIframe.addIframe({
        elementId: 'dualoo-iframe-1',
        portalUrl: url
      })
    }
    document.head.appendChild(script)
  }
}
</script>
